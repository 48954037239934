var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fond-container"},[_c('div',{staticClass:"bg"}),_c('div',{staticClass:"bg-color"}),_c('div',{staticClass:"head"},[_vm._m(0),_c('div',{staticClass:"nav"},[_c('ul',[_c('li',[_c('router-link',{attrs:{"to":"/home#page3"}},[_vm._v("关于INDIBA")])],1),_c('li',[_c('router-link',{attrs:{"to":"/home#page4"}},[_vm._v("产品方案")])],1),_vm._m(1),_c('li',[_c('router-link',{attrs:{"to":""}},[_vm._v("找到INDIBA")])],1),_c('li',[_c('router-link',{attrs:{"to":"/china"}},[_vm._v("INDIBA中国")])],1),_c('li',[_c('router-link',{attrs:{"to":"/hall"}},[_vm._v("名人堂")])],1)])]),_vm._m(2)]),_vm._m(3),_c('div',{staticClass:"vedio"},[_c('video',{attrs:{"id":"myvideo","width":"40%","height":"auto","controls":"controls","muted":"muted","autoplay":"autoplay","loop":"loop"},domProps:{"muted":true}},[_c('source',{attrs:{"src":require("../../assets/video/background.mp4"),"type":"video/mp4"}})]),_c('div',{staticClass:"video-desc"},[_vm._v(" 如何判断您所在的店铺是INDIBA®-英特波®官方认证店铺 ")])]),_c('div',{staticClass:"content1"},[_vm._m(4),_c('div',{staticClass:"choose-address"},[_c('div',{staticClass:"city-choose-item"},[_c('div',{staticClass:"city-choose-item--title"},[_vm._v("省份")]),_c('a-dropdown',{attrs:{"trigger":['click'],"placement":"topCenter","getPopupContainer":(triggerNode) => {
              return triggerNode.parentNode || _vm.document.body;
            }},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('a-menu',{on:{"click":_vm.handleProvince}},_vm._l((_vm.provinceData),function(province){return _c('a-menu-item',{key:province.code,attrs:{"value":province.code}},[_c('span',[_vm._v(_vm._s(province.name))])])}),1)]},proxy:true}])},[_c('a',{staticClass:"ant-dropdown-link",on:{"click":function($event){$event.preventDefault();}}},[_c('span',[_vm._v(_vm._s(_vm.provinceCode ? this.provinceData.filter( (item) => item.code === _vm.provinceCode )[0].name : "请选择"))]),_c('img',{attrs:{"src":require("../../assets/svg/down-arrow.svg"),"alt":""}})])])],1),_c('div',{staticClass:"city-choose-item"},[_c('div',{staticClass:"city-choose-item--title"},[_vm._v("市")]),_c('a-dropdown',{attrs:{"trigger":['click'],"placement":"topCenter","getPopupContainer":(triggerNode) => {
              return triggerNode.parentNode || _vm.document.body;
            }},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('a-menu',{on:{"click":_vm.handleCity}},_vm._l((_vm.cityData),function(city){return _c('a-menu-item',{key:city.code,attrs:{"value":city.code}},[_c('span',[_vm._v(_vm._s(city.name))])])}),1)]},proxy:true}])},[_c('a',{staticClass:"ant-dropdown-link",on:{"click":function($event){$event.preventDefault();}}},[_c('span',[_vm._v(_vm._s(_vm.cityCode ? this.cityData.filter( (item) => item.code === _vm.cityCode )[0].name : "请选择"))]),_c('img',{attrs:{"src":require("../../assets/svg/down-arrow.svg"),"alt":""}})])])],1),_c('div',{staticClass:"city-choose-item"},[_c('div',{staticClass:"city-choose-item--title"},[_vm._v("区")]),_c('a-dropdown',{attrs:{"trigger":['click'],"placement":"topCenter","getPopupContainer":(triggerNode) => {
              return triggerNode.parentNode || _vm.document.body;
            }},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('a-menu',{on:{"click":_vm.handleArea}},_vm._l((_vm.areaData),function(area){return _c('a-menu-item',{key:area.code,attrs:{"value":area.code}},[_c('span',[_vm._v(_vm._s(area.name))])])}),1)]},proxy:true}])},[_c('a',{staticClass:"ant-dropdown-link",on:{"click":function($event){$event.preventDefault();}}},[_c('span',[_vm._v(_vm._s(_vm.areaCode ? this.areaData.filter( (item) => item.code === _vm.areaCode )[0].name : "请选择"))]),_c('img',{attrs:{"src":require("../../assets/svg/down-arrow.svg"),"alt":""}})])])],1),_c('div',{staticClass:"city-choose-item search"},[_c('span'),_c('input',{attrs:{"placeholder":"请输入店铺名称按Enter键搜索","type":"text"},domProps:{"value":this.searchName},on:{"change":_vm.changeSearchName}}),_c('img',{attrs:{"src":require("../../assets/svg/search.svg"),"alt":""}})])])]),_c('div',{staticClass:"content2"},[_c('div',{staticClass:"list",attrs:{"id":"store-list-data"}},[_vm._l((_vm.listData),function(item){return _c('div',{key:item.F_CompanyId,staticClass:"list-item"},[_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":item.F_Logo,"alt":"logo"}})]),_c('div',{staticClass:"card-mid"},[_c('div',{staticClass:"card-mid--inner"},[_c('div',{staticClass:"item-title"},[_vm._v(_vm._s(item.F_FullName))]),_c('div',{staticClass:"item-citys"},[_vm._v(" "+_vm._s(item.provinceName + item.cityName + item.countryName)+" ")]),_c('div',{staticClass:"item-items-bottom"},[_c('div',{staticClass:"item-address"},[_vm._v(" "+_vm._s(item.F_Address)+" ")]),_c('a-divider',{staticStyle:{"height":"70%","border-color":"#fff"},attrs:{"type":"vertical"}}),_c('div',{staticClass:"item-address-icon",on:{"click":() =>
                    _vm.handleMapNav(
                      item.F_Longitude,
                      item.F_Latitude,
                      item.F_Address,
                      item.F_FullName
                    )}},[_c('i',{staticClass:"item-icon ri-send-plane-fill"})])],1)])]),_vm._m(5,true)])}),_c('div',{staticClass:"list-item",staticStyle:{"height":"12px"}}),_c('div',{staticClass:"pullup-tips"},[(!_vm.isPullUpLoad)?_c('div',{staticClass:"before-trigger"},[_c('span',{staticClass:"pullup-txt"},[_vm._v("上拉加载更多数据")])]):_vm._e(),(_vm.isLoading)?_c('div',{staticClass:"after-trigger"},[_c('span',{staticClass:"pullup-txt"},[_vm._v("加载中...")])]):_vm._e()])],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("../../assets/home/logo.png"),"alt":"logo"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"target":"_blank"}},[_vm._v("INDIBA资讯")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tel"},[_c('a',{attrs:{"href":"tel://010 85951863"}},[_c('i',{staticClass:"ri-phone-line",staticStyle:{"margin-right":"8px"}}),_vm._v(" 010 85951863 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('div',[_vm._v("INDIBA"),_c('sup',[_vm._v("®")]),_vm._v("-英特波"),_c('sup',[_vm._v("®")]),_c('br'),_vm._v("官方认证")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"address"},[_c('div',[_c('span'),_c('h2',[_vm._v("英特波科技（北京）有限公司")]),_c('h3',[_vm._v("北京市朝阳区建国路93号院1号楼1808室")]),_c('h3',[_vm._v("北京市朝阳区光华路4号东方梅地亚中心C座2801")])]),_c('div',[_c('span'),_c('h2',[_vm._v("英特波科技（深圳）有限公司")]),_c('h3',[_vm._v("广东省深圳市南山区粤海街道高新区社区高新南九道59号北科大厦709")])]),_c('div',[_c('span'),_c('h2',[_vm._v("上海办公室")]),_c('h3',[_vm._v("上海市长宁区古北路666号嘉麒大厦21楼03A室")]),_c('h3',[_vm._v("上海市长宁区凯旋路1398号3号楼1005室")])]),_c('div',[_c('span'),_c('h2',[_vm._v("成都办公室")]),_c('h3',[_vm._v("成都市锦江区红星路三段1号国际金融中心IFS 1号办公楼30楼02号")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-right"},[_c('img')])
}]

export { render, staticRenderFns }