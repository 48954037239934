<!--
 * @Author: xiexp
 * @Description: 
 * @Date: 2022-08-27 19:55:21
 * @LastEditTime: 2022-11-11 11:37:46
 * @FilePath: \indiba-web-new\src\pages\pc\found_new.vue
-->
<template>
  <div class="fond-container">
    <div class="bg"></div>
    <div class="bg-color"></div>
    <div class="head">
      <div class="logo">
        <img src="../../assets/home/logo.png" alt="logo" />
      </div>
      <div class="nav">
        <ul>
          <li>
            <router-link to="/home#page3">关于INDIBA</router-link>
          </li>
          <li>
            <router-link to="/home#page4">产品方案</router-link>
          </li>
          <li>
            <a target="_blank">INDIBA资讯</a>
          </li>
          <li>
            <router-link to="">找到INDIBA</router-link>
          </li>
          <li>
            <router-link to="/china">INDIBA中国</router-link>
          </li>
          <li>
            <router-link to="/hall">名人堂</router-link>
          </li>
        </ul>
      </div>
      <div class="tel">
        <a href="tel://010 85951863">
          <i style="margin-right: 8px" class="ri-phone-line"></i>
          010 85951863
        </a>
      </div>
    </div>
    <div class="title">
      <div>INDIBA<sup>®</sup>-英特波<sup>®</sup><br />官方认证</div>
    </div>
    <div class="vedio">
      <video
        id="myvideo"
        width="40%"
        height="auto"
        controls="controls"
        muted="muted"
        autoplay="autoplay"
        loop="loop"
      >
        <source src="../../assets/video/background.mp4" type="video/mp4" />
      </video>
      <div class="video-desc">
        如何判断您所在的店铺是INDIBA®-英特波®官方认证店铺
      </div>
    </div>
    <div class="content1">
      <!-- 地址 -->
      <div class="address">
        <div>
          <span></span>
          <h2>英特波科技（北京）有限公司</h2>
          <h3>北京市朝阳区建国路93号院1号楼1808室</h3>
          <h3>北京市朝阳区光华路4号东方梅地亚中心C座2801</h3>
        </div>
        <div>
          <span></span>
          <h2>英特波科技（深圳）有限公司</h2>
          <h3>广东省深圳市南山区粤海街道高新区社区高新南九道59号北科大厦709</h3>
        </div>
        <div>
          <span></span>
          <h2>上海办公室</h2>
          <h3>上海市长宁区古北路666号嘉麒大厦21楼03A室</h3>
          <h3>上海市长宁区凯旋路1398号3号楼1005室</h3>
        </div>
        <div>
          <span></span>
          <h2>成都办公室</h2>
          <h3>成都市锦江区红星路三段1号国际金融中心IFS 1号办公楼30楼02号</h3>
        </div>
      </div>
      <!-- 下拉框选项 -->
      <div class="choose-address">
        <div class="city-choose-item">
          <div class="city-choose-item--title">省份</div>
          <a-dropdown
            :trigger="['click']"
            placement="topCenter"
            :getPopupContainer="
              (triggerNode) => {
                return triggerNode.parentNode || document.body;
              }
            "
          >
            <a class="ant-dropdown-link" @click.prevent>
              <span>{{
                provinceCode
                  ? this.provinceData.filter(
                      (item) => item.code === provinceCode
                    )[0].name
                  : "请选择"
              }}</span>
              <img src="../../assets/svg/down-arrow.svg" alt="" />
            </a>
            <template #overlay>
              <a-menu @click="handleProvince">
                <a-menu-item
                  v-for="province in provinceData"
                  :key="province.code"
                  :value="province.code"
                >
                  <span>{{ province.name }}</span>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </div>
        <div class="city-choose-item">
          <div class="city-choose-item--title">市</div>
          <a-dropdown
            :trigger="['click']"
            placement="topCenter"
            :getPopupContainer="
              (triggerNode) => {
                return triggerNode.parentNode || document.body;
              }
            "
          >
            <a class="ant-dropdown-link" @click.prevent>
              <span>{{ cityCode
                  ? this.cityData.filter(
                      (item) => item.code === cityCode
                    )[0].name
                  : "请选择"}}</span>
              <img src="../../assets/svg/down-arrow.svg" alt="" />
            </a>
            <template #overlay>
              <a-menu @click="handleCity">
                <a-menu-item
                  v-for="city in cityData"
                  :key="city.code"
                  :value="city.code"
                >
                  <span>{{ city.name }}</span>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </div>
        <div class="city-choose-item">
          <div class="city-choose-item--title">区</div>
          <a-dropdown
            :trigger="['click']"
            placement="topCenter"
            :getPopupContainer="
              (triggerNode) => {
                return triggerNode.parentNode || document.body;
              }
            "
          >
            <a class="ant-dropdown-link" @click.prevent>
              <span>{{ areaCode
                  ? this.areaData.filter(
                      (item) => item.code === areaCode
                    )[0].name
                  : "请选择"}}</span>
              <img src="../../assets/svg/down-arrow.svg" alt="" />
            </a>
            <template #overlay>
              <a-menu @click="handleArea">
                <a-menu-item
                  v-for="area in areaData"
                  :key="area.code"
                  :value="area.code"
                >
                  <span>{{ area.name }}</span>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </div>
        <div class="city-choose-item search">
          <span></span>
          <input
            @change="changeSearchName"
            placeholder="请输入店铺名称按Enter键搜索"
            :value="this.searchName"
            type="text"
          />
          <img src="../../assets/svg/search.svg" alt="" />
        </div>
      </div>
    </div>
    <div class="content2">
      <div class="list" id="store-list-data">
        <div class="list-item" v-for="item in listData" :key="item.F_CompanyId">
          <div class="logo">
            <img :src="item.F_Logo" alt="logo" />
          </div>
          <div class="card-mid">
            <div class="card-mid--inner">
              <div class="item-title">{{ item.F_FullName }}</div>
              <div class="item-citys">
                {{ item.provinceName + item.cityName + item.countryName }}
              </div>
              <div class="item-items-bottom">
                <div class="item-address">
                  {{ item.F_Address }}
                </div>
                <a-divider
                  type="vertical"
                  style="height: 70%; border-color: #fff"
                />
                <div
                  class="item-address-icon"
                  @click="
                    () =>
                      handleMapNav(
                        item.F_Longitude,
                        item.F_Latitude,
                        item.F_Address,
                        item.F_FullName
                      )
                  "
                >
                  <i class="item-icon ri-send-plane-fill"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="item-right">
            <img />
          </div>
        </div>

        <div class="list-item" style="height: 12px"></div>
        <div class="pullup-tips">
          <div v-if="!isPullUpLoad" class="before-trigger">
            <span class="pullup-txt">上拉加载更多数据</span>
          </div>
          <div v-if="isLoading" class="after-trigger">
            <span class="pullup-txt">加载中...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import provinceData from "./config/province";
import areaList from "./config/areaList";
import { throttle } from "lodash";
import BScroll from "@better-scroll/core";
import Pullup from "@better-scroll/pull-up";
import MouseWheel from "@better-scroll/mouse-wheel";
import axios from "axios";
import qs from "qs";
// import vueCustomScrollbar from "vue-custom-scrollbar";
BScroll.use(Pullup);
BScroll.use(MouseWheel);
// import "vue-custom-scrollbar/dist/vueScrollbar.css";
export default {
  name: "Found",
  components: {
    // vueCustomScrollbar,
  },
  created() {
    document.title = "找到INDIBA";
  },
  mounted() {
    this.initMouseScroll();
    this.onSearch();
  },
  watch: {
    provinceCode() {
      // console.log("newValP", newVal);
      this.listData = [];
      this.cityName = "";
      this.onSearch();
    },
    cityCode() {
      // console.log("newValC", newVal);
      this.listData = [];
      this.areaName = "";
      this.onSearch();
    },
    areaCode() {
      // console.log("newValA", newVal);
      this.listData = [];
      this.onSearch();
    },
    searchName() {
      // console.log("newValS", newVal);
      this.listData = [];
      this.onSearch();
    },
  },
  data() {
    return {
      provinceData,
      provinceCode: "",
      cityCode: "",
      areaCode: "",
      cityData: [],
      areaData: [],
      currentPage: 1,
      rows: 20,
      listData: [],
      searchName: "",
      settings: {
        suppressScrollY: false,
        suppressScrollX: false,
        wheelPropagation: false,
      },
      // 上拉加载
      isPullUpLoad: false,
      isLoading: false,
      data: 30,
    };
  },
  methods: {
    // 地址导航
    handleMapNav(F_Longitude, F_Latitude, F_Address, F_FullName) {
      const params = { F_Longitude, F_Latitude, F_Address, F_FullName };
      // console.log("params", params);
      this.$router.push({
        path: "/map",
        query: params,
      });
    },
    // 省份变更
    handleProvince({ key }) {
      this.provinceCode = this.provinceData.filter(
        (item) => item.code === key
      )[0].code;
      this.cityData = this.provinceData.filter(
        (item) => item.code === key
      )[0].children;
      // 省份变更，市的数据重置
      // this.cityName = "";
    },
    // 市区变更
    handleCity({ key }) {
      // console.log("val", key);
      this.cityCode = this.cityData.filter(
        (item) => item.code === key
      )[0]?.code;
      this.areaData = this.cityData.filter(
        (item) => item.code === key
      )[0]?.children;
      // console.log("this.areaData", this.areaData);
      // this.areaName = "";
    },
    // 区县变更
    handleArea({ key }) {
      // console.log("val", key);
      this.areaCode = this.areaData.filter(
        (item) => item.code === key
      )[0]?.code;
    },
    // 搜索名字
    changeSearchName(val) {
      // console.log("val", val.target.value);
      this.searchName = val.target.value;
    },
    // 搜索
    onSearch() {
      let params = {
        page: this.currentPage,
        rows: this.rows,
      };
      if (this.searchName) {
        params.companyName = this.searchName;
      }
      if (this.provinceCode) {
        params.provinceId = this.provinceCode;
      }
      if (this.cityCode) {
        params.cityId = this.cityCode;
      }
      if (this.areaCode) {
        params.countyId = this.areaCode;
      }
      axios
        .get(
          `http://39.97.178.20:5000/Company/GetMyPageList?&${qs.stringify(
            params
          )}`
        )
        .then((res) => {
          // console.log("res11", res);
          let tempList = res.data.data.rows;
          // console.log("tempList", tempList);
          tempList.forEach((item) => {
            item.provinceName = "";
            item.cityName = "";
            item.countryName = "";
            for (let i in areaList.province_list) {
              if (item.F_ProvinceId === i) {
                item.provinceName = areaList.province_list[i] || "";
              }
            }
            for (let i in areaList.city_list) {
              if (item.F_CityId === i) {
                item.cityName = areaList.city_list[i] || "";
              }
            }
            for (let i in areaList.county_list) {
              if (item.F_CountyId === i) {
                item.countryName = areaList.county_list[i] || "";
              }
            }
          });
          // 最后展示的数据
          this.listData.push(...tempList);
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    // 初始化监听滚动
    initMouseScroll() {
      const listBox = document.getElementById("store-list-data");
      const handleScroll = async (e) => {
        let scrollTop = e.target.scrollTop;
        let clientHeight = e.target.clientHeight;
        let scrollHeight = e.target.scrollHeight;
        if (scrollHeight === scrollTop + clientHeight) {
          // console.log('滚动到底部了')
          //以及滚到底部了
          this.isPullUpLoad = true;
          this.isLoading = true;
          this.currentPage += 1;
          await this.onSearch();
          this.isPullUpLoad = false;
          this.isLoading = false;
        }
        // 打印数值
        // console.table([
        //   {
        //     label: "距顶部",
        //     value: scrollTop,
        //   },
        //   {
        //     label: "可视区高度",
        //     value: clientHeight,
        //   },
        //   {
        //     label: "滚动条总高度",
        //     value: scrollHeight,
        //   },
        //   {
        //     label: "距顶部 + 可视区高度",
        //     value: scrollTop + clientHeight,
        //   },
        // ]);
      };
      // 滚动节流
      const throttleHandleScroll = throttle(handleScroll, 1000);

      listBox.addEventListener("scroll", throttleHandleScroll);
    },
    // 初始化上拉刷新
    initBscroll() {
      this.scroll = new BScroll(this.$refs.scroll, {
        probeType: 3,
        pullUpLoad: true,
        mouseWheel: true,
      });

      // this.scroll.on("pullingUp", this.pullingUpHandler);
      // this.scroll.on("scrollEnd", (pos) => {
      //   console.log("pos", pos);
      // });
      // this.bscroll = new BScroll(this.$refs.scroll, {
      //   pullUpLoad: true,
      // });
      // // console.log("this.bscroll",this.bscroll);
      // this.bscroll.on("pullingUp", this.pullingUpHandler);
    },
    // async pullingUpHandler() {
    //   this.isPullUpLoad = true;
    //   this.currentPage += 1;
    //   // 更新页数
    //   await this.onSearch(); // 请求数据
    //   this.bscroll.finishPullUp();
    //   this.bscroll.refresh();
    //   this.isPullUpLoad = false;
    // },
    async pullingUpHandler() {
      // console.log("滚动到底部了");
      this.isPullUpLoad = true;

      await this.requestData();
      // await this.onSearch(); // 请求数据

      this.scroll.finishPullUp();
      this.scroll.refresh();
      this.isPullUpLoad = false;
    },
    async requestData() {
      try {
        const newData = await this.ajaxGet(/* url */);
        this.data += newData;
      } catch (err) {
        // handle err
        console.log(err);
      }
    },
    ajaxGet(/* url */) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(20);
        }, 1000);
      });
    },
  },
};
</script>

<style lang="less" less="scoped">
// @import '~vue-simple-scrollbar/dist/vue-simple-scrollbar.css';
.fond-container {
  position: relative;
  .bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 1124px;
    background-image: url(../../assets/img/china-bg2.png);
  }
  .bg-color {
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 1124px;
    background-color: rgba(46, 89, 175, 0.85);
  }
  .head {
    display: flex;
    justify-content: space-around;
    z-index: 999;
    position: absolute;
    width: 100%;
    top: 4.8vh;
    .logo {
    }
    .nav {
      max-width: 860px;
      width: 46%;
      ul {
        width: 100%;
        display: flex;
        justify-content: space-around;
        height: 21px;
        /* font-size: 21px; */
        font-family: Microsoft YaHei;
        font-weight: 400;
        li {
          display: inline-block;
          a {
            color: #fff;
            cursor: pointer;
          }
        }
      }
    }
    .tel {
      margin-bottom: 0.5rem;
      a {
        font-size: 16px;
        font-family: Arial;
        font-weight: 400;
        color: #fff;
      }
    }
  }
  .title {
    position: absolute;
    left: 110px;
    top: 40vh;
    font-size: 40px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #fff;
  }

  .vedio {
    position: absolute;
    right: 60px;
    top: 190px;
    video {
      width: 450px;
    }
    .video-desc {
      margin-top: 8px;
      text-align: center;
      color: #fff;
    }
  }
  .content1 {
    position: absolute;
    width: 92%;
    left: 50%;
    transform: translateX(-50%);
    top: 70vh;
    .address {
      display: flex;
      justify-content: space-between;
      div {
        flex: 1;
        padding: 0 8px;
        span {
          width: 60px;
          display: inline-block;
          height: 5px;
          background-color: #fff;
          margin-bottom: 10px;
        }
        h2,
        h3 {
          color: #fff;
          line-height: 24px;
          font-size: 18px;
        }
        h3 {
          font-size: 14px;
        }
      }
    }
    .choose-address {
      margin-top: 36px;
      display: flex;
      justify-content: space-between;
      padding: 0 8px;
      .city-choose-item {
        width: 210px;
        border-bottom: 2px solid white;
        .ant-dropdown-link {
          margin: 16px 0;
          display: flex;
          justify-content: space-between;
          span {
            color: #fff;
            font-size: 15px;
          }
          img {
            width: 18px;
            margin-left: 8px;
          }
        }
        .ant-dropdown {
          z-index: 99999 !important;
          .ant-dropdown-menu {
            max-height: 200px;
            overflow: scroll;
          }
        }
      }
      .search {
        display: flex;
        justify-content: flex-end;
        align-items: end;
        input {
          height: 32px;
          width: 100%;
          border: none;
          outline: none;
          background-color: transparent;
          color: #fff;
        }
        ::placeholder {
          color: #fff;
        }
        img {
          width: 18px;
          height: 18px;
          margin-bottom: 6px;
        }
      }
      .city-choose-item--title {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        font-weight: bold;
      }
    }
  }
  .content2 {
    position: absolute;
    top: 114vh;
    background-color: #0a3aa5;
    width: 100%;
    display: flex;
    justify-content: center;
    min-height: 250px;
    .list {
      width: 82%;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-around;
      margin-top: 16px;
      max-height: 500px;
      overflow-y: scroll;
      &::-webkit-scrollbar-track {
        background-color: #a1a1c9;
      }

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #ffffff;
      }

      .list-item {
        display: flex;
        height: 150px;
        flex: 0 0 45%;
        min-width: 440px;
        .logo {
          display: flex;
          align-items: center;
          margin-right: 30px;
          img {
            width: 100px;
            height: 90px;
            background-color: #fff;
            object-fit: contain;
          }
        }
        .card-mid {
          width: 380px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .card-mid--inner {
            background-color: #6b8ac8;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            border-radius: 6px;
            padding: 10px;
            color: white;
            .item-title {
              font-size: 18px;
              font-weight: 700;
              color: #fff;
              margin-bottom: 6px;
            }
            .item-citys,
            .item-address {
              font-size: 13px;
              flex: 1;
            }
            .item-address-icon {
              text-align: right;
              cursor: pointer;
            }
            .item-items-bottom {
              display: flex;
              align-items: center;
              width: 100%;
            }
          }
        }
      }
    }
  }
  .pullup-tips {
    width: 100%;
    text-align: center;
    color: #e9dfdf;
    margin: 12px;
  }
}
</style>
